<template>
  <Layout>
    <div class="row home-bg">
      <div class="col-12"></div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'

export default {
  name: 'Home',

  components: {
    Layout,
  },

  data() {
    return {
      title: 'Home',
      items: [
        {
          text: 'Home',
          active: true,
        },
      ],
    }
  },
}
</script>
